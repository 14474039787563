import { render, staticRenderFns } from "./home_pc.vue?vue&type=template&id=1dfd4f88&scoped=true"
import script from "./home_pc.vue?vue&type=script&lang=js"
export * from "./home_pc.vue?vue&type=script&lang=js"
import style0 from "./home_pc.vue?vue&type=style&index=0&id=1dfd4f88&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dfd4f88",
  null
  
)

export default component.exports