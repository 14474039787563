// // 测试
// const VUE_APP_WS_URL = 'ws://121.196.205.21:8080/websocket';
// const VUE_APP_BASEURL = 'http://121.196.205.21:8080';

// 正式
// const VUE_APP_WS_URL = 'wss://www.saxtrader.net/websocket';
// const VUE_APP_BASEURL = 'https://www.saxtrader.net';
// const VUE_APP_WS_URL = 'wss://www.galaxytrader.cloud/websocket';
// const VUE_APP_BASEURL = 'https://www.galaxytrader.cloud';
const VUE_APP_WS_URL = 'wss://www.galaxygoldtraders.com/websocket';
const VUE_APP_BASEURL = 'https://www.galaxygoldtraders.com';
// 测试
// const VUE_APP_WS_URL = 'ws://127.0.0.1:8080/websocket';//测试
// const VUE_APP_BASEURL = 'http://127.0.0.1:8080';//测试
// 本地
// const VUE_APP_BASEURL = 'https://u798377y57.zicp.fun';

export { VUE_APP_WS_URL, VUE_APP_BASEURL };